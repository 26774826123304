<template>
    <div class="userinfo">
        <div class="index_head">
            <div class="index_top">
                <div class="left" @click="redirectIndex">
                    <img src="../../../public/logo.png" alt="" />
                    <p>工业文档查阅系统</p>
                </div>
                <div class="right">
                    <div class="user_info">
                        <div class="user_head">
                            <img :src="userInfo.avatar" alt="" />
                        </div>
                        <div class="user_mes">
                            <h3>{{ userInfo.realname }}</h3>
                            <p>部门：<span>{{ userInfo.department }}</span></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="user_info_main">
            <div class="left">
                <div class="box">
                    <div class="item" @click="userinfo">
                        账号信息
                    </div>
                    <div class="item active">
                        我的收藏
                    </div>
                    <div class="item" @click="userpassword">
                        修改密码
                    </div>
                    <div class="item" @click="logout">
                        退出登录
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="right_tt">
                    <div class="right_tt_ll">
                        <p>我的收藏</p>
                    </div>
                    <div class="right_tt_ll">
<!--                        <input type="text" placeholder="请输入关键词" />-->
                    </div>
                </div>
                <div class="right_list">

                    <div class="item" v-for="item in list" @click="previewPdf(item.document_id)">
                        <div class="item_tt">
                            <div class="ll">
                                <p>{{ item.title }}</p>
                            </div>
                            <div class="rr" @click.stop="collect(item.document_id)">
                                <span>{{ categoryList[category.indexOf(item.category_id)].name }}</span>
                                <p>取消收藏</p>
                                <img src="../../../public/icon-sc_active.png" alt="" />
                            </div>
                        </div>
                        <div class="item_cc">
                            <p>{{ item.desc }}</p>
                        </div>
                        <div class="item_bb">
                            <p>查看详情</p>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    </div>

</template>

<script>
    export default {
        data() {
            return {
                userInfo: {},
                category: [],
                categoryList: [],
                list: [],
            }
        },

        mounted() {
            this.getUserInfo();
            this.getCategory();
        },

        methods: {
            /**
             * 个人信息
             */
            getUserInfo() {
                this.$http.get('/user/getUserInfo').then(res => {
                    this.userInfo = res.data.data
                });
            },

            /**
             * 分类
             */
            getCategory() {
                this.$http.get('/category/all').then(res => {
                    var category = [];
                    for (var i = 0; i < res.data.data.length; i++) {
                        category.push(res.data.data[i].id);
                    }
                    this.categoryList = res.data.data;
                    this.category = category;
                    this.getCollect();
                });
            },

            /**
             * 收藏列表
             */
            getCollect(){
                this.$http.get('/collect/all').then(res => {
                    this.list = res.data.data
                });
            },

            /**
             * 收藏或取消
             */
            collect(id){
                const loading = this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$http.post('/collect/operate', {document_id: id}).then(res => {
                    loading.close();
                    if (res.data.code == 0) {
                        this.getCollect();
                    } else {
                        this.$message({type: 'error', message: res.data.msg})
                    }

                });
            },

            /**
             * 详情
             */
            previewPdf(id){
                const loading = this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$http.get('/index/preview?id=' + id).then(res => {
                    loading.close();
                    if (res.data.code == 0) {
                        // var url = res.data.data.url + '?file=' + encodeURIComponent(res.data.data.file) + '&title=' + encodeURIComponent(res.data.data.title);
                        var url = res.data.data.file;
                        if(res.data.data.file_type == 2){
                            url += '?title=' + encodeURIComponent(res.data.data.title);
                        }
                        window.open(url, '_blank');
                    } else {
                        this.$message({type: 'error', message: res.data.msg})
                    }
                });
            },

            redirectIndex(){
                this.$router.push('/')
            },
            userinfo() {
                this.$router.push('/userinfo')
            },
            userpassword() {
                this.$router.push('/userpassword')
            },
            logout(){
                this.$messageBox.confirm('确定要退出吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    localStorage.removeItem('access_token')
                    this.$message({
                        type: 'success',
                        message: '退出成功!'
                    });
                    this.$router.push('/login')
                });
            },
        }
    };
</script>

<style lang="less" scoped>
    .index_head {
        width: 100%;
        background: #fff;

        .index_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;

            .left {
                display: flex;
                align-items: center;

                img {
                    display: block;
                    width: 81px;
                    margin-right: 24px;
                }

                p {
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 600;
                    font-size: 32px;
                    color: #231815;
                    line-height: 48px;
                }
            }

            .right {
                display: flex;
                align-items: center;

                .user_info {
                    display: flex;
                    align-items: center;
                    position: relative;
                    cursor: pointer;

                    .user_head {
                        img {
                            display: block;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            margin-right: 8px;
                        }
                    }

                    .user_mes {
                        h3 {
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 600;
                            font-size: 14px;
                            color: #231815;
                            line-height: 22px;
                        }

                        p {
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 400;
                            font-size: 12px;
                            color: #8D8D8D;
                            line-height: 18px;
                            display: flex;
                            align-items: center;

                            span {
                                color: #443B39;
                            }
                        }
                    }

                }
            }
        }


    }

    .user_info_main {
        width: 1150px;
        margin: 0 auto;
        margin-top: 26px;
        display: flex;
        justify-content: space-between;

        .left {
            width: 200px;
            margin-right: 30px;

            .box{
                background: #FFFFFF;
                border-radius: 16px 16px 16px 16px;
                padding: 18px 0;
                .item {
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 16px;
                    color: #8D8D8D;
                    line-height: 32px;
                    margin-bottom: 8px;
                    padding-left: 24px;
                    cursor: pointer;
                }

                .item.active {
                    border-left: 5px solid #EB9114;
                }

                .item:last-child {
                    margin-bottom: 0;
                }
            }

        }

        .right {
            flex: 1;
            background: #FFFFFF;
            border-radius: 8px 8px 8px 8px;
            padding: 30px;
            padding-bottom: 0;
            box-sizing: border-box;
            .right_tt{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .right_tt_ll{

                    p{
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 16px;
                        color: #231815;
                        line-height: 34px;

                    }
                    input{
                        display: block;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #231815;
                        line-height: 34px;
                        width: 215px;
                        height: 34px;
                        background: #F6F6F6;
                        border-radius: 34px;
                        padding: 0 16px;
                        box-sizing: border-box;
                    }
                }
            }
            .right_list{
                .item{
                    padding-bottom: 32px;
                    border-bottom: 1px solid #DCDCDC;
                    .item_tt{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 16px 0;
                        .ll{
                            flex: 1;
                            display: flex;
                            align-items: center;
                            p{
                                width: 700px;
                                font-family: Microsoft YaHei, Microsoft YaHei;
                                font-weight: 400;
                                font-size: 24px;
                                color: #3D3D3D;
                                line-height: 40px;
                                overflow:hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                -o-text-overflow:ellipsis;
                            }

                        }
                        .rr{
                            display: flex;
                            align-items: center;
                            span{
                                background: rgba(130,162,255,0.12);
                                border-radius: 2px 2px 2px 2px;
                                margin-left: 15px;
                                font-family: Microsoft YaHei, Microsoft YaHei;
                                font-weight: 400;
                                font-size: 16px;
                                color: #527FFF;
                                line-height: 24px;
                                padding: 0 4px;
                            }
                            p{
                                font-family: Microsoft YaHei, Microsoft YaHei;
                                font-weight: 400;
                                font-size: 14px;
                                color: #EB9114;
                                line-height: 22px;
                                margin-right: 4px;
                            }
                            img{
                                display: block;
                                width: 24px;
                            }
                        }
                    }
                    .item_cc{
                        margin-bottom: 16px;
                        p{
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #443B39;
                            line-height: 22px;
                        }
                    }
                    .item_bb{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        p{
                            width: 100px;
                            height: 36px;
                            background: #EB9114;
                            border-radius: 2px 2px 2px 2px;
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #FFFFFF;
                            line-height: 36px;
                            text-align: center;
                        }
                    }
                }
                .item:last-child{
                    border-bottom: 0;
                }
            }

        }
    }
</style>
